import * as React from "react";
import Footer from "../components/footer";
import Hero from "../components/hero";
import Layout from "../components/layout";
import styled from "styled-components";
import Block from "../components/block";
import StatementParagraph from "../components/statement-paragraph";
import SplitFlexBox from "../components/split-flex-box";
import LargeButton from "../components/large-button";
import { StaticImage } from "gatsby-plugin-image";
import { BREAKPOINTS, GREYBOX_COLOR } from "../consts";
import CollaborationIcon from "../images/companyicons/collaboration.svg";
import ResultsIcon from "../images/companyicons/results.svg";
import EfficiencyIcon from "../images/companyicons/efficiency.svg";
import CreativityIcon from "../images/companyicons/creativity.svg";
import IntegrityIcon from "../images/companyicons/integrity.svg";
import { graphql } from "gatsby";
import AnimatedEntry from "../components/animated-entry";
import { useMediaQuery } from "react-responsive";

const Page = styled.div``;

const VerticalSlideContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 0 2rem;
  height: ${(props) => (props.isMobile ? "auto" : "100vh")};
  align-items: center;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
  > div {
    display: flex;
    flex-direction: column;
    border-left: 2px solid #080808;
    padding: 2rem;
    height: 100%;
    position: relative;
    flex: 1;
    max-height: 70%;
    box-sizing: border-box;
    min-height: ${(props) => (props.isMobile ? "400px" : "600px")};

    width: ${(props) => (props.isMobile ? "100%" : "auto")};
    margin-bottom: ${(props) => (props.isMobile ? "3rem" : "0")};

    > span {
      font-weight: 500;
      font-size: 6rem;
      font-family: "Epilogue";
    }
    > h5 {
      font-size: 1.2rem;
      font-weight: 400;
      margin: 0;
      flex: 1;
    }
    > p {
      position: absolute;
      bottom: 1rem;
      left: 2rem;
      width: 70%;
      margin-bottom: 0;
    }
  }
`;
const TeamGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const TeamMember = styled.div`
  flex: ${(props) => (props.isMobile ? "100%" : "50%")};

  box-sizing: border-box;
  margin-bottom: 2rem;
  max-width: ${(props) => (props.isMobile ? "100%" : "50%")};

  &:nth-child(odd) {
    padding-right: ${(props) => (props.isMobile ? "0" : "0.5rem;")};
  }
  &:nth-child(even) {
    padding-left: ${(props) => (props.isMobile ? "0" : "0.5rem;")};
  }
`;
const TeamMemberName = styled.p`
  font-weight: 500;
  font-size: 1.2rem;
  margin: 0 0 0.5rem;
`;
const TeamMemberImageStyle = {
  marginBottom: `1rem`,
};
const BuildFutureButtonContainer = styled.div`
  text-align: ${(props) => (props.isMobile ? `center` : `right`)};
  justify-content: ${(props) => (props.isMobile ? `center` : `flex-end`)};
  display: flex;
`;
const BuildFutureContainer = styled(SplitFlexBox)`
  align-items: center;
  h2 {
    text-align: ${(props) => (props.isMobile ? "center" : "left")};
    margin-bottom: ${(props) => (props.isMobile ? "1.2rem" : "0")};
  }
`;

const AttributeBoxGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
const AttributeBox = styled.div`
  flex: ${(props) => (props.isMobile ? "100%" : "33%")};
  padding: ${(props) => (props.isMobile ? "0" : "0 6%")};
  box-sizing: border-box;
  margin-bottom: 2rem;
  max-width: ${(props) => (props.isMobile ? "100%" : "33%")};

  > div {
    padding-bottom: 1rem;
    flex: 33%;
    > span {
      display: inline-block;
      height: 50px;
      width: 50px;
      border-radius: 100%;
    }
  }
  h5 {
    margin: 1.2rem 0 2rem;
    font-size: 1.05rem;
    font-weight: 400;
  }
`;

const CompanyPage = ({ data }) => {
  const [isMobile, setIsMobile] = React.useState(false);
  const mediaQuery = useMediaQuery({
    query: `(max-width: ${BREAKPOINTS["mobile"]}px)`,
  });
  React.useEffect(() => {
    setIsMobile(mediaQuery);
  }, [mediaQuery, isMobile]);

  return (
    <Page className={isMobile ? "is-mobile" : ""}>
      <Hero
        backgroundColor="white"
        text="Technology meets human creativity"
        imageData={isMobile ? data.mobileHeroImage : data.heroImage}
      />

      <Layout title={"Company"}>
        <Block justifyContent={"right"} fullHeight={true}>
          <StatementParagraph>
            Towo Labs is a tech company that takes a conscientious
            approach to making self custody of crypto assets easy and secure.
          </StatementParagraph>
        </Block>

        <Block justifyContent={"left"} fullHeight={true}>
          <VerticalSlideContainer isMobile={isMobile}>
            <div>
              <span>01</span>
              <h5>Security</h5>
              <p>
                Our top priority is and always will be security. All our software goes through extensive testing and third-party audits.
              </p>
            </div>
            <div>
              <span>02</span>
              <h5>Privacy</h5>
              <p>
                We take your privacy extremely seriously. We adhere to the principles of privacy by design and data minimization.
              </p>
            </div>
            <div>
              <span>03</span>
              <h5>Utility</h5>
              <p>
                Software should be easy to use and understand. We always strive to improve usability and make blockchains more accessible.
              </p>
            </div>
          </VerticalSlideContainer>
        </Block>

        <Block justifyContent={"left"} fullHeight={true}>
          <StatementParagraph>
            <h2>Working with us</h2>
            We're a remote-first team of software engineers and blockchain experts working with passion, high integrity and a large degree of personal freedom.
          </StatementParagraph>
        </Block>

        <Block backgroundColor={GREYBOX_COLOR}>
          <BuildFutureContainer isMobile={isMobile}>
            <h2>
              {isMobile ? (
                <span>Help build the future</span>
              ) : (
                <span>
                  Help build <br /> the future
                </span>
              )}
            </h2>
            <BuildFutureButtonContainer isMobile={isMobile}>
              <a href="mailto:careers@towolabs.com">
                <LargeButton>careers@towolabs.com</LargeButton>
              </a>
            </BuildFutureButtonContainer>
          </BuildFutureContainer>
        </Block>

        <Footer />
      </Layout>
    </Page>
  );
};

export const query = graphql`
  query CompanyPageQuery {
    heroImage: file(name: { eq: "company" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    mobileHeroImage: file(name: { eq: "company-mobile" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

export default CompanyPage;
